
import { defineComponent, PropType } from "vue";
import { NewsCollectionModel } from "../../../entities/news-collection-model";
import "@sverigesradio/stil/components/stil-text.js";
import "@sverigesradio/stil/components/stil-icon.js";
import "@sverigesradio/stil/components/stil-button-rounded.js";
import NewsModuleCard from "@/components/cards/NewsModuleCard.vue";
import * as appService from "../../../public/appService";
import { NativeRepository } from "@/repositories/native-repository";
import PauseIcon from "@/components/icons/PauseIcon.vue";
import { usePlayerStateStore } from "@/stores/playerState";
import { THEME_DARK } from "@/constants";
import { FirebaseTrackingEvent } from "../../../entities/firebase-tracking-event";
import { useExperimentStore } from "@/stores/experiments";
import { useVersionStore } from "@/stores/version";
import StandaloneTopNewsCarousel from "@/components/carousels/StandaloneTopNewsCarousel.vue";
import StandaloneNewsModuleCard from "@/components/cards/StandaloneNewsModuleCard.vue";
import NewsArticleHeroCard from "@/components/cards/NewsArticleHeroCard.vue";
import { GoogleAnalyticsTrackingEvent } from "../../../entities/google-analytics-tracking-event";
import { useNativeStore } from "@/stores/nativeStore";
import { wrapAround } from "@/util/array-utils";
import { setEquals } from "@/util/set-utils";
import PlayIcon from "../icons/PlayIcon.vue";
import CardLarge from "./CardLarge.vue";
import CardMedium from "./CardMedium.vue";
import CardMediumCarousel from "@/components/carousels/CardMediumCarousel.vue";

import {
  mapArticleToPuffCardItem,
  PuffCardItem,
} from "../../../entities/puff-package-model";
import InlineDivider from "./InlineDivider.vue";
import {
  KilkayaClickEvent,
  kilkayaClick,
} from "@/util/tracking-utils";
export default defineComponent({
  name: "StandaloneTopNewsCard",
  components: {
    NewsArticleHeroCard,
    StandaloneNewsModuleCard,
    StandaloneTopNewsCarousel,
    PauseIcon,
    NewsModuleCard,
    PlayIcon,
    CardLarge,
    InlineDivider,
    CardMedium,
    CardMediumCarousel,
  },
  props: {
    newsCollectionModel: {
      type: Object as PropType<NewsCollectionModel>,
      required: true,
    },
    moduleName: String,
  },
  data() {
    return {
      theme: null as string | null,
    };
  },
  setup() {
    return {
      playerStateStore: usePlayerStateStore(),
      experimentStore: useExperimentStore(),
      versionStore: useVersionStore(),
      nativeStore: useNativeStore(),
    };
  },
  computed: {
    showPauseButton(): boolean {
      return (
        this.isPlayerPlaying &&
        this.isCurrentPlaylistPlaying
      );
    },
    isCurrentPlaylistPlaying(): boolean {
      const playerStateArticlesSet = new Set(
        (
          this.playerStateStore
            ?.idsOfCurrentPlaylist || ""
        ).split(", ")
      );
      const playlistArticlesSet = new Set(
        this.idsOfArticlesInPlaylist
      );
      return setEquals(
        playerStateArticlesSet,
        playlistArticlesSet
      );
    },
    isPlayerPlaying(): boolean {
      return !!this.playerStateStore?.isPlaying;
    },
    idsOfArticlesInPlaylist(): string[] {
      if (this.newsCollectionModel) {
        return this.newsCollectionModel.articles.map(
          (article) => article.id.toString()
        );
      } else {
        return [];
      }
    },
    hasData(): boolean {
      return this.newsCollectionModel !== null;
    },
    isDarkMode(): boolean {
      return (
        this.nativeStore.currentTheme ===
        THEME_DARK
      );
    },
    playButtonTheme(): string {
      return this.nativeStore.currentTheme ===
        THEME_DARK
        ? "light"
        : "dark";
    },
    showPlayAll(): boolean {
      return !this.experimentStore.hide_play_all;
    },
    moreNewsInCarousel(): boolean {
      return this.experimentStore
        .more_news_position;
    },
    cardItemsData(): PuffCardItem[] {
      return (
        this.newsCollectionModel?.articles || []
      ).map(mapArticleToPuffCardItem);
    },
    newsFromEkotMediumCardCarousel(): boolean {
      return this.experimentStore
        .news_from_ekot_card_medium_carousel;
    },
  },
  methods: {
    playAllClicked(newsCollectionModel) {
      this.playNewsCollection(
        newsCollectionModel,
        0
      );
      this.sendPlayAllTrackingEvent(
        newsCollectionModel
      );
    },
    playArticleClicked(
      newsCollectionModel,
      index: number
    ) {
      this.playNewsCollection(
        newsCollectionModel,
        index
      );
      this.sendPlayArticleTrackingEvent(
        newsCollectionModel,
        index
      );
    },
    playNewsCollection(
      newsCollectionModel,
      startIndex: number = 0
    ) {
      const wrappedAroundArticles = wrapAround(
        newsCollectionModel.articles,
        startIndex
      );
      const newsCollectionToPlay = {
        ...newsCollectionModel,
        articles: wrappedAroundArticles,
        startIndex: 0,
        type: "playArticleCollection",
      };
      appService.playNewsCollection(
        JSON.stringify(newsCollectionToPlay)
      );
    },
    pausePlayer() {
      NativeRepository.pausePlayer();
    },
    async navigationClicked(newsCollectionModel) {
      await this.openNewsPage(
        newsCollectionModel
      );
      this.sendNavigationClickTrackingEvent(
        newsCollectionModel
      );
    },
    async openNewsPage(
      newsCollectionModel: NewsCollectionModel
    ) {
      NativeRepository.openNewsPage(
        newsCollectionModel,
        0
      );
    },
    async articleClicked(
      articleId,
      index: Number,
      newsCollectionModel: NewsCollectionModel | null
    ) {
      await this.openNewsArticle(articleId);
      if (newsCollectionModel) {
        this.sendArticleClickTrackingEvent(
          newsCollectionModel,
          index
        );
      }
    },
    async openNewsArticle(articleId) {
      NativeRepository.openNewsArticle(articleId);
    },

    sendKilkayaEvent(
      interactionType: KilkayaClickEvent["interactionType"],
      index?: number
    ) {
      const article = index != undefined
        ? this.newsCollectionModel.articles[index]
        : null;

      const kilkayaEvent: KilkayaClickEvent = {
        audioType: "Article",
        channelName: article?.channelName || "",
        soundName: "",
        moduleTitle:
          this.newsCollectionModel
            .newsModuleHeadline,
        moduleName: this.moduleName || "",
        position: index,
        id: article?.id ?? -1,
        itemHeadline: article?.title || "",
        interactionType: interactionType,
        programName: article?.programName || "",
      };
      kilkayaClick(kilkayaEvent);
    },

    sendPlayArticleTrackingEvent(
      newsCollectionModel: NewsCollectionModel,
      index
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent =
        {
          eventName: "modulklick",
          parametersMap: {
            modulnamn:
              newsCollectionModel.trackingLabel,
            modultyp: "nyhetsmodul",
            spellista:
              newsCollectionModel.trackingLabel,
            kanalnamn:
              newsCollectionModel.articles[index]
                .channelName,
            ljudlangd: Math.round(
              newsCollectionModel.articles[index]
                .audio.durationInMillis /
                1000 /
                60
            ).toFixed(0),
            klickinteraktion: "spela",
            listposition: index.toFixed(0),
          },
        };
      appService.sendFirebaseTrackingEvent(
        firebaseTrackingEvent
      );

      this.sendKilkayaEvent("spela", index);
    },
    sendArticleClickTrackingEvent(
      newsCollectionModel: NewsCollectionModel,
      index
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent =
        {
          eventName: "modulklick",
          parametersMap: {
            modulnamn:
              newsCollectionModel.trackingLabel,
            modultyp: "nyhetsmodul",
            spellista:
              newsCollectionModel.trackingLabel,
            klickinteraktion: "klick",
            listposition: index.toFixed(0),
          },
        };
      appService.sendFirebaseTrackingEvent(
        firebaseTrackingEvent
      );

      this.sendKilkayaEvent("klick", index);
    },

    sendNavigationClickTrackingEvent(
      newsCollectionModel: NewsCollectionModel
    ) {
      let firebaseTrackingEvent: FirebaseTrackingEvent =
        {
          eventName: "modulklick",
          parametersMap: {
            modulnamn:
              newsCollectionModel.trackingLabel,
            modultyp: "nyhetsmodul",
            spellista:
              newsCollectionModel.trackingLabel,
            klickinteraktion: "klick",
            listposition: "fler_nyheter",
          },
        };
      appService.sendFirebaseTrackingEvent(
        firebaseTrackingEvent
      );

      this.sendKilkayaEvent("klick_fler_nyheter");
    },
    sendPlayAllTrackingEvent(
      newsCollectionModel: NewsCollectionModel
    ) {
      let title = "";
      if (newsCollectionModel.genre == "Local") {
        title = "lokala nyheter";
      } else {
        title =
          newsCollectionModel.listHeadline.toLowerCase();
      }
      let firebaseTrackingEvent: FirebaseTrackingEvent =
        {
          eventName: "modulklick",
          parametersMap: {
            modulnamn:
              newsCollectionModel.trackingLabel,
            modultyp: "nyhetsmodul",
            spellista:
              newsCollectionModel.trackingLabel,
            klickinteraktion: "spela",
            listposition: "spela_alla",
          },
        };
      let googleAnalyticsTrackingEvent: GoogleAnalyticsTrackingEvent =
        {
          event: "start",
          category: "Karusell",
          trackingLabel:
            newsCollectionModel.trackingLabel,
          customDimensions: {
            36: title,
            46: title,
            66: "webview",
          },
          customMetrics: {},
          campaignUrl: null,
          trackingValue: null,
        };
      appService.sendGoogleAnalyticsTrackingEvent(
        googleAnalyticsTrackingEvent
      );
      appService.sendFirebaseTrackingEvent(
        firebaseTrackingEvent
      );
      this.sendKilkayaEvent("spela_alla");
    },
  },
});
