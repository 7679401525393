import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "divider"
}
const _hoisted_2 = {
  key: 1,
  class: "card-padding"
}
const _hoisted_3 = {
  key: 0,
  class: "spacing"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemporarySkeletonItem = _resolveComponent("TemporarySkeletonItem")!
  const _component_ModuleTitle = _resolveComponent("ModuleTitle")!
  const _component_CardTextMetaImage = _resolveComponent("CardTextMetaImage")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_TemporarySkeletonItem, {
              key: 0,
              class: "card-padding"
            }))
          : (_ctx.hasData && _ctx.display)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_ModuleTitle, { title: _ctx.title }, null, 8, ["title"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editorialCollectionViewModels, (editorialCollection, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: editorialCollection.title
                  }, [
                    _createVNode(_component_CardTextMetaImage, {
                      headline: editorialCollection.headline,
                      description: editorialCollection.description,
                      "aria-label": editorialCollection.ariaLabel,
                      picture: editorialCollection.picture,
                      meta: editorialCollection.meta,
                      onNavigate: ($event: any) => (_ctx.navigateToEditorialCollection(index))
                    }, null, 8, ["headline", "description", "aria-label", "picture", "meta", "onNavigate"]),
                    (index < 2)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])), [
                [_directive_observe_visibility, { callback: (isVisible) => _ctx.onBecomeVisible(isVisible), once: true, throttle: 300, }]
              ])
            : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, { callback: (isAlmostVisible) => _ctx.onAlmostVisible(isAlmostVisible), intersection: { rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN}, once: true, throttle: 100, }]
      ])
    : _createCommentVNode("", true)
}