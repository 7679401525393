import { THEME_LIGHT } from "../src/constants.ts";
import { logger } from "../src/util/logger.ts";

// These calls are all to the native apps.
export function checkConnectionToNative() {
  if (window.JSBridge) {
    if (!window.JSBridge.getTheme) {
      console.warn("JSBridge: getTheme() does not exist");
    }
    if (!window.JSBridge.openEpisode) {
      console.warn("JSBridge: openEpisode() does not exist");
    }
    if (!window.JSBridge.playEpisode) {
      console.warn("JSBridge: playEpisode() does not exist");
    }
    if (!window.JSBridge.playNewsCollection) {
      console.warn("JSBridge: playNewsCollection() does not exist");
    }
    if (!window.JSBridge.openProgram) {
      console.warn("JSBridge: openProgram() does not exist");
    }
    if (!window.JSBridge.getLocalWeatherStation) {
      console.warn("JSBridge: getLocalWeatherStation() does not exist");
    }
    if (!window.JSBridge.openBrowser) {
      console.warn("JSBridge: openBrowser() does not exist");
    }
    if (!window.JSBridge.sendGoogleAnalyticsTrackingScreenEvent) {
      console.warn(
        "JSBridge: sendGoogleAnalyticsTrackingScreenEvent() does not exist",
      );
    }
    if (!window.JSBridge.playStory) {
      console.warn("JSBridge: playStory() does not exist");
    }
    if (!window.JSBridge.getUserId) {
      console.warn("JSBridge: getUserId() does not exist");
    }
    if (!window.JSBridge.getLocalChannelId) {
      console.warn("JSBridge: getLocalChannelId() does not exist");
    }
    if (!window.JSBridge.showLocalChannelSelection) {
      console.warn("JSBridge: showLocalChannelSelection() does not exist");
    }
    if (!window.JSBridge.showExtraChannelSelection) {
      console.warn("JSBridge: showExtraChannelSelection() does not exist");
    }
    if (!window.JSBridge.getLocalWeatherStation) {
      console.warn("JSBridge: getLocalWeatherStation() does not exist");
    }
    if (!window.JSBridge.openWeatherStationSetting) {
      console.warn("JSBridge: openWeatherStationSetting() does not exist");
    }
    if (!window.JSBridge.getIdOfCurrentItemPlaying) {
      console.warn("JSBridge: getIdOfCurrentItemPlaying() does not exist");
    }
    if (!window.JSBridge.getIdsOfCurrentPlaylistPlaying) {
      console.warn("JSBridge: getIdsOfCurrentPlaylistPlaying() does not exist");
    }
    if (!window.JSBridge.getPlayerState) {
      console.warn("JSBridge: getPlayerState() does not exist");
    }
    if (!window.JSBridge.pausePlayer) {
      console.warn("JSBridge: pausePlayer() does not exist");
    }
    if (!window.JSBridge.openNewsTab) {
      console.warn("JSBridge: openNewsTab() does not exist");
    }
    if (!window.JSBridge.openNewsArticle) {
      console.warn("JSBridge: openNewsArticle() does not exist");
    }
    if (!window.JSBridge.playNewsArticle) {
      console.warn("JSBridge: playNewsArticle() does not exist");
    }
  }
  if (window["webkit"] && window["webkit"].messageHandlers) {
    if (!window["webkit"].messageHandlers.JSBridge) {
      console.warn("JSBridge: Messagehandler does not exist");
    }
    if (!window["webkit"].messageHandlers.JSBridgeWithReply) {
      console.warn("JSBridgeWithReply: Messagehandler does not exist");
    }
    if (!window["webkit"].messageHandlers.FirebaseTracking) {
      console.warn("FirebaseTracking: Messagehandler does not exist");
    }
    if (!window["webkit"].messageHandlers.PersonalizationTracking) {
      console.warn("PersonalizationTracking: Messagehandler does not exist");
    }
    if (!window["webkit"].messageHandlers.GoogleAnalyticsTracking) {
      console.warn("GoogleAnalyticsTracking: Messagehandler does not exist");
    }
    if (!window["webkit"].messageHandlers.GoogleAnalyticsScreenTracking) {
      console.warn(
        "GoogleAnalyticsScreenTracking: Messagehandler does not exist",
      );
    }
  }
}

export function openEpisode(episodeId) {
  try {
    if (window.JSBridge) {
      window.JSBridge.openEpisode(episodeId);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = { type: "openEpisode", id: episodeId };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not open episode");
  }
}

export function playEpisode(episodeId) {
  try {
    if (window.JSBridge) {
      window.JSBridge.playEpisode(episodeId);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = { type: "playEpisode", id: episodeId };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not play episode");
  }
}

export function playNewsCollection(newsCollectionJsonModel) {
  try {
    if (window.JSBridge) {
      window.JSBridge.playNewsCollection(newsCollectionJsonModel);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      window["webkit"].messageHandlers.JSBridge.postMessage(
        newsCollectionJsonModel,
      );
    }
  } catch {
    console.error("JSBridgeError: Could not play news collection");
  }
}

export function playOnDemandPlaylist(playlistModel) {
  try {
    if (window.JSBridge) {
      window.JSBridge.playOnDemandPlaylist(JSON.stringify(playlistModel));
    } else if (window["webkit"].messageHandlers.JSBridge) {
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify({ ...playlistModel, type: "playOnDemandPlaylist" }),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not play on demand playlist");
  }
}

export function openProgram(programId) {
  try {
    if (window.JSBridge) {
      window.JSBridge.openProgram(programId);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "openProgram",
        id: programId,
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not open program");
  }
}

export function showPushSettings() {
  try {
    if (window["webkit"].messageHandlers.JSBridge) {
      const payload = { type: "showPushSettings" };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not shop push settings");
  }
}

export function openEditorialCollection(
  editorialCollectionId,
  type,
  index,
  trackingLabel,
) {
  try {
    if (window.JSBridge) {
      window.JSBridge.openEditorialCollection(type, index);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "openEditorialCollection",
        id: editorialCollectionId,
        trackingLabel: trackingLabel,
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not open editorial collection");
  }
}

export function playChannel(channelId) {
  try {
    if (window.JSBridge) {
      window.JSBridge.playChannel(channelId);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "playChannel",
        id: channelId,
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not play channel");
  }
}

export function getUserId() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getUserId();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getUserId",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(failureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get User Id");
    return null;
  }
}

export function getLocalWeatherStation() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getLocalWeatherStation();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getLocalWeatherStation",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(failureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get saved Weather Channel");
    return null;
  }
}

export function getLocalChannelId() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getLocalChannelId();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getLocalChannelId",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(failureCallback);
    }
    return null;
  } catch {
    console.error("JSBridgeError: Could not get local channel");
    return null;
  }
}

export function showLocalChannelSelection() {
  try {
    if (window.JSBridge) {
      window.JSBridge.showLocalChannelSelection();
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "showLocalChannelSelection",
      };
      return window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not show local channel selection");
  }
}

export function getSavedExtraChannel() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getExtraChannelId();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getExtraChannelId",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(failureCallback);
    }
    return null;
  } catch {
    console.error("JSBridgeError: Could not get saved extra channel");
  }
}

export function showExtraChannelSelection() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.showExtraChannelSelection();
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "showExtraChannelSelection",
      };
      return window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not select extra channel");
  }
}

export function openWeatherStationSetting() {
  try {
    if (window.JSBridge) {
      window.JSBridge.openWeatherStationSetting();
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "openWeatherStationSetting",
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not open weather station setting");
  }
}

export function getExperimentValues() {
  try {
    if (window.JSBridge) {
      const experiments = window.JSBridge.getExperiments();
      if (experiments == null) {
        return null;
      }
      return JSON.parse(experiments);
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getExperiments",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(failureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get experiments");
  }
}

export function getFontSize() {
  try {
    if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getFontSize",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(failureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get font size");
  }
}

export function getTheme() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getTheme();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getTheme",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(failureCallback);
    } else {
      return THEME_LIGHT;
    }
  } catch {
    console.error("JSBridgeError: Could not get theme");
  }
}

export function getOS() {
  try {
    if (window.JSBridge) {
      return "android";
    } else if (window["webkit"].messageHandlers.JSBridge) {
      return "iOS";
    } else {
      return "";
    }
  } catch {
    console.error("JSBridgeError: Could not get OS");
  }
}

export function programListeningHistoryFailureCallback(error) {
  logger.logError("iOS listening history error: " + error, error, "");
  return error;
}

export function currentItemPlayingFailureCallback(error) {
  logger.logError("iOS get current playing item id error: " + error, error, "");
  return error;
}

export function currentPlaylistIdsPlayingFailureCallback(error) {
  logger.logError(
    "iOS get list of ids for currently playing playlist error: " + error,
    error,
    "",
  );
  return error;
}

export function playerStateFailureCallback(error) {
  logger.logError("iOS get player state error: " + error, error, "");
  return error;
}

export function failureCallback(error) {
  return error;
}

export function successCallback(result) {
  return result;
}

export function updateWebViewPremiumTrackingData(trackingDataJson) {
  try {
    if (window.JSBridge)
      window.JSBridge.updateWebViewPremiumTrackingData(trackingDataJson);
  } catch {
    console.error("JSBridgeError: Could not update premium tracking data");
  }
}

export function sendWebViewPremiumCardClickedEvent(trackingData) {
  try {
    if (window.JSBridge) {
      window.JSBridge.sendWebViewPremiumCardClickedEvent(
        JSON.stringify(trackingData),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not send premium card clicked event");
  }
}

export function sendWebViewPremiumClickedEvent() {
  try {
    if (window.JSBridge) {
      window.JSBridge.sendWebViewPremiumClickedEvent();
    }
  } catch {
    console.error("JSBridgeError: Could not send premium clicked event");
  }
}

export function getProgramListeningHistory() {
  try {
    if (window.JSBridge) {
      const programsListeningHistoryAsString =
        window.JSBridge.getProgramListeningHistory();
      return programsListeningHistoryAsString
        .split(",")
        .map((str) => Number(str));
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getProgramListeningHistory",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(programListeningHistoryFailureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get listening history");
  }
}

export function openBrowser(url) {
  try {
    if (window.JSBridge) {
      window.JSBridge.openBrowser(url);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = { type: "openBrowser", url: url };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not get open link");
  }
}

const AVERAGE_SCREEN_HEIGHT = 710; // window.innerHeight on the webview of a standard iPhone sized simulator
export function sendFirebaseTrackingEvent(firebaseTrackingEvent) {
  let trackingEvent = firebaseTrackingEvent;

  if (!["modul_visas", "skarmvisning"].includes(trackingEvent.eventName)) {
    const scrollDepth = parseFloat(
      (window.scrollY / AVERAGE_SCREEN_HEIGHT).toFixed(1),
    );
    trackingEvent = {
      ...trackingEvent,
      parametersMap: {
        ...trackingEvent?.parametersMap,
        scrolldjup: scrollDepth,
      },
    };
  }

  try {
    if (window.JSBridge) {
      window.JSBridge.sendFirebaseTrackingEvent(JSON.stringify(trackingEvent));
    } else if (window["webkit"].messageHandlers.FirebaseTracking) {
      window["webkit"].messageHandlers.FirebaseTracking.postMessage(
        JSON.stringify(trackingEvent),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not send firebase tracking event");
  }
}

export function sendGoogleAnalyticsTrackingEvent(googleAnalyticsTrackingEvent) {
  try {
    if (window.JSBridge) {
      window.JSBridge.sendGoogleAnalyticsTrackingEvent(
        JSON.stringify(googleAnalyticsTrackingEvent),
      );
    } else if (window["webkit"].messageHandlers.GoogleAnalyticsTracking) {
      window["webkit"].messageHandlers.GoogleAnalyticsTracking.postMessage(
        JSON.stringify(googleAnalyticsTrackingEvent),
      );
    }
  } catch {
    console.error(
      "JSBridgeError: Could not send google analytics tracking event",
    );
  }
}

export function sendGoogleAnalyticsTrackingScreenEvent(
  googleAnalyticsTrackingScreenEvent,
) {
  try {
    if (window.JSBridge) {
      window.JSBridge.sendGoogleAnalyticsTrackingScreenEvent(
        JSON.stringify(googleAnalyticsTrackingScreenEvent),
      );
    } else if (window["webkit"].messageHandlers.GoogleAnalyticsScreenTracking) {
      window[
        "webkit"
      ].messageHandlers.GoogleAnalyticsScreenTracking.postMessage(
        JSON.stringify(googleAnalyticsTrackingScreenEvent),
      );
    }
  } catch {
    console.error(
      "JSBridgeError: Could not send google analytics tracking screen event",
    );
  }
}

export function sendPersonalizationTrackingEvent(personalizationTrackingData) {
  try {
    if (window.JSBridge) {
      window.JSBridge.sendPersonalizationTrackingEvent(
        JSON.stringify(personalizationTrackingData),
      );
    } else if (window["webkit"].messageHandlers.PersonalizationTracking) {
      window["webkit"].messageHandlers.PersonalizationTracking.postMessage(
        JSON.stringify(personalizationTrackingData),
      );
    }
  } catch {
    console.error(
      "JSBridgeError: Could not send personalization tracking event",
    );
  }
}

export function playStory(storyId) {
  try {
    if (window.JSBridge) {
      window.JSBridge.playStory(storyId);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        id: storyId,
        type: "playStory",
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not play story");
  }
}

export function getIdOfCurrentItemPlaying() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getIdOfCurrentItemPlaying();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getIdOfCurrentItemPlaying",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(currentItemPlayingFailureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get idOfCurrentItemPlaying");
  }
}

export function getIdsOfCurrentPlaylistPlaying() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getIdsOfCurrentPlaylistPlaying();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getIdsOfCurrentPlaylistPlaying",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(currentPlaylistIdsPlayingFailureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get idOfCurrentItemPlaying");
  }
}

export function getPlayerState() {
  try {
    if (window.JSBridge) {
      return window.JSBridge.getPlayerState();
    } else if (window["webkit"].messageHandlers.JSBridgeWithReply) {
      const payload = {
        type: "getPlayerState",
      };
      return window["webkit"].messageHandlers.JSBridgeWithReply.postMessage(
        JSON.stringify(payload),
      )
        .then(successCallback)
        .catch(playerStateFailureCallback);
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get playerState");
  }
}

export function pausePlayer() {
  try {
    if (window.JSBridge) {
      window.JSBridge.pausePlayer();
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "pausePlayer",
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    } else {
      return null;
    }
  } catch {
    console.error("JSBridgeError: Could not get pausePlayer");
  }
}

export function openNewsTab() {
  try {
    if (window.JSBridge) {
      window.JSBridge.openNewsTab();
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "openNewsTab",
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not open news tab");
  }
}

export function openNewsArticle(articleId) {
  try {
    if (window.JSBridge) {
      window.JSBridge.openNewsArticle(articleId);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "openNewsArticle",
        id: articleId,
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not open news article");
  }
}

export function playNewsArticle(articleId) {
  try {
    if (window.JSBridge) {
      window.JSBridge.playNewsArticle(articleId);
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "playNewsArticle",
        id: articleId,
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not play news article");
  }
}

export function openNewsPage(
  newsCollectionModel,
  localChannelId,
  playlistId,
  tabTitle,
  version,
) {
  try {
    if (window.JSBridge) {
      if (version >= 2.0) {
        const payload = JSON.stringify({
          id: playlistId,
          title: tabTitle,
          type: newsCollectionModel.type,
        });
        window.JSBridge.openNewsPage(payload);
      } else {
        window.JSBridge.openNewsPage(tabTitle);
      }
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "showNewsPage",
        title: newsCollectionModel.listHeadline,
        localChannelId: localChannelId ? localChannelId.toString() : "",
        curatedPlaylistId: String(playlistId),
        genre: newsCollectionModel.genre,
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch (e) {
    console.log(e);
    console.error("JSBridgeError: Could not open news page");
  }
}

export function openWebView(url, title) {
  try {
    if (window.JSBridge) {
      const payload = { url, title };
      window.JSBridge.openWebView(JSON.stringify(payload));
    } else if (window["webkit"].messageHandlers.JSBridge) {
      const payload = {
        type: "openWebView",
        url,
        title,
      };
      window["webkit"].messageHandlers.JSBridge.postMessage(
        JSON.stringify(payload),
      );
    }
  } catch {
    console.error("JSBridgeError: Could not open webview");
  }
}
export function openSommar2024CampaignWebView(url, title) {
  try {
    if (window.JSBridge) {
      const payload = { url, title };
      window.JSBridge.openSommar2024CampaignWebView(JSON.stringify(payload));
    }
  } catch {
    console.error("JSBridgeError: Could not open Sommar 2024 campaign webview");
  }
}
