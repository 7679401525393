import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-424c928c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "startpage-container"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 7 }
const _hoisted_10 = { key: 8 }
const _hoisted_11 = { key: 9 }
const _hoisted_12 = { key: 10 }
const _hoisted_13 = { key: 11 }
const _hoisted_14 = { key: 12 }
const _hoisted_15 = { key: 13 }
const _hoisted_16 = { key: 14 }
const _hoisted_17 = { key: 15 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Promotion = _resolveComponent("Promotion")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Greeting = _resolveComponent("Greeting")!
  const _component_Channels = _resolveComponent("Channels")!
  const _component_RightNow = _resolveComponent("RightNow")!
  const _component_PuffPackageContainer = _resolveComponent("PuffPackageContainer")!
  const _component_EditorialCollection = _resolveComponent("EditorialCollection")!
  const _component_PremiumItem = _resolveComponent("PremiumItem")!
  const _component_Live = _resolveComponent("Live")!
  const _component_StandaloneTopNewsContainer = _resolveComponent("StandaloneTopNewsContainer")!
  const _component_OldTopNews = _resolveComponent("OldTopNews")!
  const _component_FixedNews = _resolveComponent("FixedNews")!
  const _component_TopNews = _resolveComponent("TopNews")!
  const _component_StandalonePodcast = _resolveComponent("StandalonePodcast")!
  const _component_CuratedNews = _resolveComponent("CuratedNews")!
  const _component_RecommendedEpisodes = _resolveComponent("RecommendedEpisodes")!
  const _component_NewsBroadcastsEpisodes = _resolveComponent("NewsBroadcastsEpisodes")!
  const _component_RecommendedPrograms = _resolveComponent("RecommendedPrograms")!
  const _component_ListeningHistoryPrograms = _resolveComponent("ListeningHistoryPrograms")!
  const _component_PopularPrograms = _resolveComponent("PopularPrograms")!
  const _component_Feedback = _resolveComponent("Feedback")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (!_ctx.hasError)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.loaded)
          ? (_openBlock(), _createBlock(_component_Promotion, {
              key: 0,
              "promotion-index": _ctx.promotionIndex
            }, null, 8, ["promotion-index"]))
          : _createCommentVNode("", true),
        (!_ctx.isProd)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "env-indicator",
              style: _normalizeStyle(`background-color: ${_ctx.envIndicatorColor}`)
            }, null, 4))
          : _createCommentVNode("", true),
        (_ctx.loaded)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              id: "start",
              class: _normalizeClass({ 'has-native-top-navigation': _ctx.hasNativeTopNavigation })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.structure, (item) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: item.title
                }, [
                  (!_ctx.hasNativeTopNavigation)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (item.type === 'logo')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createVNode(_component_Logo),
                              _createVNode(_component_Greeting)
                            ]))
                          : (item.type === 'channels')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createVNode(_component_Channels)
                              ]))
                            : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (item.type === 'breaking' && item.referenceId)
                    ? (_openBlock(), _createBlock(_component_RightNow, {
                        key: 1,
                        id: item.referenceId,
                        moduleName: item.moduleName
                      }, null, 8, ["id", "moduleName"]))
                    : (item.type === 'puffPackage')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (item.referenceId && _ctx.showPuffPackageModule)
                            ? (_openBlock(), _createBlock(_component_PuffPackageContainer, {
                                key: 0,
                                puffPackageId: item.referenceId,
                                "module-name": item.moduleName
                              }, null, 8, ["puffPackageId", "module-name"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (item.type === 'editorialCollection')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_EditorialCollection, {
                              title: item.title,
                              variant: item.variant,
                              "module-name": item.moduleName
                            }, null, 8, ["title", "variant", "module-name"])
                          ]))
                        : (item.type === 'premium')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _createVNode(_component_PremiumItem, {
                                variant: item.variant,
                                moduleName: item.moduleName
                              }, null, 8, ["variant", "moduleName"])
                            ]))
                          : (item.type === 'live')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_Live, {
                                  variant: item.variant,
                                  moduleName: item.moduleName
                                }, null, 8, ["variant", "moduleName"])
                              ]))
                            : (item.type === 'news' && item.variant === 'topNews')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  (_ctx.showNewTopNewsModule)
                                    ? (_openBlock(), _createBlock(_component_StandaloneTopNewsContainer, {
                                        key: 0,
                                        moduleName: item.moduleName
                                      }, null, 8, ["moduleName"]))
                                    : (_openBlock(), _createBlock(_component_OldTopNews, { key: 1 }))
                                ]))
                              : (item.type === 'news' && item.variant === 'newsPlaylists')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    (
              _ctx.showNewTopNewsModule &&
              (_ctx.showNewTopNewsDesign || _ctx.newFixedNewsDesignEnabled)
            )
                                      ? (_openBlock(), _createBlock(_component_FixedNews, { key: 0 }))
                                      : _createCommentVNode("", true),
                                    (
              _ctx.showNewTopNewsModule &&
              !_ctx.showNewTopNewsDesign &&
              !_ctx.newFixedNewsDesignEnabled
            )
                                      ? (_openBlock(), _createBlock(_component_TopNews, {
                                          key: 1,
                                          moduleName: item.moduleName
                                        }, null, 8, ["moduleName"]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : (item.type === 'program' && item.variant === 'primary')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                      _createVNode(_component_StandalonePodcast, {
                                        variant: item.variant,
                                        moduleName: item.moduleName
                                      }, null, 8, ["variant", "moduleName"])
                                    ]))
                                  : (item.type === 'news' && item.variant === 'curatedNews')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                        _createVNode(_component_CuratedNews)
                                      ]))
                                    : (item.type === 'episode' && item.variant === 'recommended')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                          _createVNode(_component_RecommendedEpisodes, {
                                            title: item.title,
                                            moduleName: item.moduleName
                                          }, null, 8, ["title", "moduleName"])
                                        ]))
                                      : (
            item.type === 'episode' && item.variant === 'newsBroadcasts'
          )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                            _createVNode(_component_NewsBroadcastsEpisodes, {
                                              title: item.title,
                                              showAll: true,
                                              moduleName: item.moduleName
                                            }, null, 8, ["title", "moduleName"])
                                          ]))
                                        : (item.type === 'program' && item.variant === 'recommended')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                              _createVNode(_component_RecommendedPrograms, {
                                                title: item.title,
                                                moduleName: item.moduleName
                                              }, null, 8, ["title", "moduleName"])
                                            ]))
                                          : (
            item.type === 'program' && item.variant === 'listeningHistory'
          )
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                _createVNode(_component_ListeningHistoryPrograms, {
                                                  title: item.title,
                                                  moduleName: item.moduleName
                                                }, null, 8, ["title", "moduleName"])
                                              ]))
                                            : (item.type === 'program' && item.variant === 'popular')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                  _createVNode(_component_PopularPrograms, {
                                                    title: item.title,
                                                    moduleName: item.moduleName
                                                  }, null, 8, ["title", "moduleName"])
                                                ]))
                                              : (item.type === 'links')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                    (_ctx.loaded && _ctx.feedbackModuleEnabled)
                                                      ? (_openBlock(), _createBlock(_component_Feedback, {
                                                          key: 0,
                                                          variant: item.type
                                                        }, null, 8, ["variant"]))
                                                      : _createCommentVNode("", true),
                                                    _createVNode(_component_PageFooter, {
                                                      title: item.title
                                                    }, null, 8, ["title"])
                                                  ]))
                                                : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}