import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33d51448"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "right-now gutter"
}
const _hoisted_2 = {
  key: 1,
  class: "text-puff-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineSkeleton = _resolveComponent("LineSkeleton")!
  const _component_TextSkeleton = _resolveComponent("TextSkeleton")!
  const _component_TextPuff = _resolveComponent("TextPuff")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.isLoading || _ctx.hasData)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_TextSkeleton, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_LineSkeleton),
                _createVNode(_component_LineSkeleton, { width: 220 })
              ]),
              _: 1
            }))
          : (
        _ctx.hasData && _ctx.rightNowReponse !== null
      )
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightNowReponse.items, (item) => {
                  return (_openBlock(), _createBlock(_component_TextPuff, {
                    key: item.referenceId,
                    onClick: () => _ctx.openItem(item),
                    prefix: item.prefix,
                    separator: ":",
                    headline: item.headline
                  }, null, 8, ["onClick", "prefix", "headline"]))
                }), 128))
              ])), [
                [_directive_observe_visibility, {
        callback: (isVisible) =>
          _ctx.onBecomeVisible(isVisible),
        once: true,
        throttle: 300,
      }]
              ])
            : _createCommentVNode("", true)
      ])), [
        [_directive_observe_visibility, {
      callback: (isAlmostVisible) =>
        _ctx.onAlmostVisible(isAlmostVisible),
      intersection: {
        rootMargin: _ctx.ON_ALMOST_VISIBLE_ROOT_MARGIN,
      },
      once: true,
      throttle: 100,
    }]
      ])
    : _createCommentVNode("", true)
}