import { wrapAround } from "@/util/array-utils";
import * as appService from "../../public/appService";
import { useVersionStore } from "@/stores/version";
import { PuffItemType } from "entities/puff-package-model";
import {
  NewsCollectionModel,
  TabTitleFromGenre,
} from "../../entities/news-collection-model";

export const NativeRepository = {
  version(): number | null {
    return useVersionStore().webViewVersion;
  },

  async getProgramListeningHistory(): Promise<number[] | null> {
    return await appService.getProgramListeningHistory(); // await needed for iOS!
  },

  openNewsPage(newsCollectionModel: NewsCollectionModel, localChannelId): void {
    const version = this.version();
    if (version !== null && version >= 2.0) {
      appService.openNewsPage(
        newsCollectionModel,
        localChannelId,
        newsCollectionModel.playlistLookupId,
        newsCollectionModel.listHeadline,
        version,
      );
    } else if (version !== null && version >= 1.4) {
      const tabTitle =
        TabTitleFromGenre[newsCollectionModel.genre] || "Toppnyheter";
      appService.openNewsPage(
        newsCollectionModel,
        localChannelId,
        newsCollectionModel.playlistLookupId,
        tabTitle,
        version,
      );
    }
  },

  playEpisode(episodeId) {
    const version = this.version();
    if (version && version >= 1.2) {
      appService.playEpisode(episodeId);
    }
  },

  playNewsArticle(articleId) {
    const version = this.version();
    if (version && version >= 1.2) {
      appService.playNewsArticle(articleId);
    }
  },

  openEpisode(episodeId) {
    const version = this.version();
    if (version && version >= 1.2) {
      appService.openEpisode(episodeId);
    }
  },

  openNewsArticle(articleId) {
    const version = this.version();
    if (version && version >= 1.2) {
      appService.openNewsArticle(articleId);
    }
  },

  openNewsTab() {
    const version = this.version();
    if (version && version >= 1.2) {
      appService.openNewsTab();
    }
  },

  showPushSettings() {
    const version = this.version();
    if (version && version >= 1.4) {
      appService.showPushSettings();
    }
  },

  async getPlayerState(): Promise<string> {
    const version = this.version();
    if (version !== null && version >= 1.3) {
      return await appService.getPlayerState();
    } else {
      return "";
    }
  },

  async getIdsOfCurrentPlaylistPlaying(): Promise<string> {
    const version = this.version();
    if (version !== null && version >= 1.3) {
      return await appService.getIdsOfCurrentPlaylistPlaying();
    } else {
      return "";
    }
  },

  async getIdOfCurrentItemPlaying(): Promise<string> {
    const version = this.version();
    if (version !== null && version >= 1.3) {
      return await appService.getIdOfCurrentItemPlaying();
    } else {
      return "";
    }
  },

  pausePlayer() {
    const version = this.version();
    if (version !== null && version >= 1.3) {
      return appService.pausePlayer();
    }
  },

  playOnDemandPlaylist(
    title: string,
    trackedIdentifier: string,
    items: { id: number; type: PuffItemType }[],
    startIndex: number = 0,
  ) {
    const version = this.version();
    if (!version || version < 1.5) {
      return;
    }

    const wrappedItems = wrapAround(
      items,
      startIndex,
      (item) => item.type === "Episode" || item.type === "Article",
    );
    const mappedWrappedItems = wrappedItems.map((item) => ({
      ...item,
      type: item.type.toUpperCase(),
    }));

    const playlist = {
      title,
      trackedIdentifier,
      items: mappedWrappedItems,
      trackedContentType: "MIXED_PLAYLIST",
    };

    appService.playOnDemandPlaylist(playlist);
  },

  // This will never be called for Android version < 2.1
  openSommar2024CampaignWebView(title: string) {
    const url = "https://next.sr.se/kampanj/sommar-2024/webview";
    const fallbackUrl =
      "https://sverigesradio.se/artikel/sommarpratare-2024-hela-listan";
    const version = this.version();
    const os = appService.getOS();

    if (!version || version < 1.6) {
      // Open the url in a browser as a fallback.
      appService.openBrowser(fallbackUrl);
      return;
    }
    if (os === "android") {
      appService.openSommar2024CampaignWebView(url, title);
    } else {
      appService.openWebView(url, title);
    }
  },

  openWebView(url: string, title: string) {
    const version = this.version();
    if (!version || version < 1.6) {
      // Open the url in a browser as a fallback.
      appService.openBrowser(url);
      return;
    }

    appService.openWebView(url, title);
  },
};
